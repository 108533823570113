import styled from 'styled-components';
import { Merchant } from '../../types/types';
import { BASE_FONT } from '../../theme/fonts';

export const MerchantStatus = styled.div<{
    status: Merchant['status'];
}>`
    position: absolute;
    bottom: -2px;
    right: -2px;

    width: 8px;
    height: 8px;
    border-radius: 999px;
    border: 2px solid #1f2126;
    background: ${({ status }) =>
        status === 'live'
            ? '#20de40'
            : status === 'new'
            ? '#f0df1b'
            : status === 'blocked'
            ? '#ED383C'
            : '#ffa500'};
`;

export const IbanWrapper = styled.div`
    margin-top: 10px;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
`;

export const IbanPrefix = styled(BASE_FONT)`
    color: #6f747e;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 109.091% */
`;

export const Iban = styled(BASE_FONT)`
    margin-left: 4px;
    color: var(--Dirty-Blue, #0c161d);
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 109.091% */
`;
