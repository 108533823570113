import ContentLoader from 'react-content-loader';

type Props = {
    id?: number;
    status?: 'SUCCEEDED' | 'PENDING';
};

export const TdStatus = ({ id, status }: Props) => {
    return (
        <td>
            <button
                type="button"
                style={{
                    position: 'relative',
                }}
            >
                {status ? (
                    <div
                        className={`label ${
                            status === 'SUCCEEDED'
                                ? 'label-success-light'
                                : status === 'PENDING'
                                ? 'label-warning-light'
                                : 'label-default'
                        }`}
                    >
                        {status}
                    </div>
                ) : !id ? (
                    <div
                        style={{
                            width: 60,
                            height: 15,
                        }}
                    >
                        <ContentLoader viewBox="0 0 60 15">
                            <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="60"
                                height="15"
                            />
                        </ContentLoader>
                    </div>
                ) : (
                    ''
                )}
            </button>
        </td>
    );
};
