import React, { useCallback, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import authLogo from '../../assets/img/auth-logo.svg';
import { FormError } from '../../components';
import { config } from '../../config';
import { register } from '../../services/AuthService';
import { checkPassword, isEmail, removeSnake } from '../../services/helpers';
import { RegisterProps } from '../../types/types';

export const SignUp = () => {
    const history = useHistory();
    const login = useCallback(() => {
        history.push('/sign-in');
    }, [history]);

    const requiredFields = useMemo(() => {
        return [
            'first_name',
            'last_name',
            'email',
            'password',
            'password_confirmation',
        ];
    }, []);

    const [user, setUser] = useState<Partial<RegisterProps>>({
        is_individual: 0,
    });

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const userChanged = useCallback(
        (key: string, value: string | 0 | 1) => {
            setUser(prevState => ({
                ...prevState,
                [key]: value,
            }));
            if (
                (key === 'email' && !isEmail(value.toString())) ||
                (key === 'first_name' && !value) ||
                (key === 'last_name' && !value) ||
                (key === 'password' && !checkPassword(value.toString())) ||
                (key === 'password_confirmation' && value !== user?.password)
            ) {
                const errorMessage =
                    key === 'email'
                        ? 'The email must be a valid email address'
                        : key === 'password'
                        ? 'The password must be 6–30 characters, and include a number and a letter'
                        : key === 'password_confirmation'
                        ? 'The password confirmation does not match'
                        : `The ${removeSnake(key)} field is required`;

                if (!errors.hasOwnProperty(key))
                    setErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors, user.password],
    );

    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const onTermsClick = useCallback(() => {
        setTermsAccepted(prevState => {
            if (prevState) {
                setErrors(prevStateErrors => {
                    return {
                        ...prevStateErrors,
                        terms: ['Please accept terms and conditions'],
                    };
                });
            } else {
                setErrors(prevStateErrors => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { terms: _, ...res } = prevStateErrors;
                    return res;
                });
            }
            return !prevState;
        });
    }, []);

    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (
                !errors.hasOwnProperty(item) &&
                (!user?.hasOwnProperty(item) || user[item] === undefined)
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        if (!termsAccepted) {
            allFieldsFilled = false;
            setErrors(prevStateErrors => {
                return {
                    ...prevStateErrors,
                    terms: ['Please accept terms and conditions'],
                };
            });
        }
        return allFieldsFilled;
    }, [errors, requiredFields, termsAccepted, user]);

    const signUp = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await register(user as RegisterProps);
                setButtonDisabled(false);
                if (response?.status === 200) {
                    history.push('/');
                }
                if (response?.response?.data?.errors) {
                    const {
                        first_name,
                        last_name,
                        email,
                        phone,
                        password,
                        password_confirmation,
                        is_individual,
                        ...other
                    } = response?.response?.data?.errors;
                    if (first_name)
                        setErrors(prevState => ({
                            ...prevState,
                            first_name,
                        }));
                    if (last_name)
                        setErrors(prevState => ({
                            ...prevState,
                            last_name,
                        }));
                    if (email)
                        setErrors(prevState => ({ ...prevState, email }));
                    if (phone)
                        setErrors(prevState => ({ ...prevState, phone }));
                    if (password)
                        setErrors(prevState => ({ ...prevState, password }));
                    if (password_confirmation)
                        setErrors(prevState => ({
                            ...prevState,
                            password_confirmation,
                        }));
                    if (is_individual)
                        setErrors(prevState => ({
                            ...prevState,
                            is_individual,
                        }));

                    if (Object.keys(other).length)
                        console.log('other errors:', other);
                }
            }
        }
    }, [errors, checkEmptyValues, user, history]);

    return (
        <div className="block block-auth">
            <form
                className="auth-content"
                action=""
                style={{ position: 'relative' }}
            >
                <div className="auth-container">
                    <div className="auth-wrapper auth-wrapper-lg">
                        <div className="auth-icon">
                            <img
                                src={authLogo}
                                alt="auth logo"
                                style={{
                                    width: 120,
                                    height: 60,
                                    marginBottom: 20,
                                }}
                            />
                        </div>
                        <div className="auth-title">Register a new account</div>
                        {/* <div className="auth-description">
                            Show your interest by subscribing to our newsletter
                        </div> */}
                        <div className="form">
                            <div className="row">
                                <div className="col-xs-12 col-lg-6 form-group">
                                    <div className="form-label">First name</div>
                                    <input
                                        className="form-control form-control-lg"
                                        value={user?.first_name || ''}
                                        name="first_name"
                                        placeholder="FIRST NAME"
                                        onChange={e =>
                                            userChanged(
                                                'first_name',
                                                e.target.value,
                                            )
                                        }
                                    />
                                    <FormError errors={errors?.first_name} />
                                </div>
                                <div className="col-xs-12 col-lg-6 form-group">
                                    <div className="form-label">Last name</div>
                                    <input
                                        className="form-control form-control-lg"
                                        value={user?.last_name || ''}
                                        name="last_name"
                                        placeholder="LAST NAME"
                                        onChange={e =>
                                            userChanged(
                                                'last_name',
                                                e.target.value,
                                            )
                                        }
                                    />
                                    <FormError errors={errors?.last_name} />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="form-label">Email</div>
                                <input
                                    className="form-control form-control-lg"
                                    name="email"
                                    type="email"
                                    placeholder="EMAIL"
                                    value={user?.email || ''}
                                    onChange={e =>
                                        userChanged('email', e.target.value)
                                    }
                                />
                                <FormError errors={errors?.email} />
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-lg-6 form-group">
                                    <div className="form-label">Password</div>
                                    <input
                                        className="form-control form-control-lg"
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="PASSWORD"
                                        value={user?.password || ''}
                                        onChange={e =>
                                            userChanged(
                                                'password',
                                                e.target.value,
                                            )
                                        }
                                    />
                                    <FormError errors={errors?.password} />
                                </div>
                                <div className="col-xs-12 col-lg-6 form-group">
                                    <div className="form-label">
                                        Password confirmation
                                    </div>
                                    <input
                                        className="form-control form-control-lg"
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        type="password"
                                        placeholder="CONFIRM PASSWORD"
                                        value={user.password_confirmation || ''}
                                        onChange={e =>
                                            userChanged(
                                                'password_confirmation',
                                                e.target.value,
                                            )
                                        }
                                    />
                                    <FormError
                                        errors={errors?.password_confirmation}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-lg-12 form-group">
                                    <div className="form-checkbox checkbox-narrow">
                                        <input
                                            type="checkbox"
                                            id="remember"
                                            checked={termsAccepted}
                                            onChange={onTermsClick}
                                        />
                                        <label
                                            className="checkbox-label"
                                            htmlFor="remember"
                                        >
                                            <div className="checkbox-label-box">
                                                <div className="mdi mdi-check" />
                                            </div>
                                            <div className="checkbox-label-text">
                                                I accept{' '}
                                                <a
                                                    className="checkbox-label-link"
                                                    href={config.privacyPolicy}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    the privacy policy{' '}
                                                </a>
                                                and{' '}
                                                <a
                                                    className="checkbox-label-link"
                                                    href={
                                                        config.termsAndConditionsUrl
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    terms and conditions{' '}
                                                </a>
                                                of Troc Circle
                                            </div>
                                        </label>
                                    </div>
                                    <FormError errors={errors?.terms} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-lg-12 form-group">
                                    <div className="form-checkbox checkbox-narrow">
                                        <input
                                            type="checkbox"
                                            id="is_individual"
                                            checked={user?.is_individual === 1}
                                            onChange={() =>
                                                userChanged(
                                                    'is_individual',
                                                    user?.is_individual ? 0 : 1,
                                                )
                                            }
                                        />
                                        <label
                                            className="checkbox-label"
                                            htmlFor="is_individual"
                                        >
                                            <div className="checkbox-label-box">
                                                <div className="mdi mdi-check" />
                                            </div>
                                            <div className="checkbox-label-text">
                                                I am individual
                                            </div>
                                        </label>
                                    </div>
                                    <FormError errors={errors?.is_individual} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auth-wrapper">
                        <button
                            type="button"
                            className="button button-lg button-fill button-dark"
                            onClick={signUp}
                        >
                            FINISH REGISTRATION
                        </button>
                        <div className="button button-lg button-fill button-text button-disabled button-disabled-text">
                            Already have an account?
                        </div>
                        <button
                            type="button"
                            className="button-lg button button-fill button-dark button-dark-outline"
                            onClick={login}
                        >
                            LOGIN
                        </button>
                    </div>
                </div>
                {!!isButtonDisabled && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Loader
                            type="MutatingDots"
                            color="#f37749"
                            visible
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            </form>
            <div className="auth-aside auth-aside-sign_up" />
        </div>
    );
};
