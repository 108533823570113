import React, { memo } from 'react';
import { FormError } from '../../components';

type OtherErrorsProps = {
    otherErrors: string[];
};

export const OtherErrors = memo(({ otherErrors }: OtherErrorsProps) => {
    return otherErrors?.length ? (
        <>
            <div className="form-title">
                {`Other error${otherErrors?.length > 1 ? 's' : ''}`}
            </div>
            <FormError errors={otherErrors} />
        </>
    ) : null;
});
