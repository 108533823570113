import styled from 'styled-components';

export const Header = styled.div`
    width: 100%;
    height: 61px;
    left: 0px;
    top: 0px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #eaecf0;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
`;

export const HeaderTitle = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;

    color: #000000;
`;

export const CloseButton = styled.button`
    border: 0;
    padding: 0;
    background: white;
    cursor: pointer;
`;

export const CloseButtonText = styled.em`
    font-size: 24px;
    &:hover {
        color: gray;
    }
`;

export const Content = styled.div`
    background: #fbfbfb;
    padding-bottom: 10px;
    position: relative;
`;

export const RowElement = styled.div`
    flex-direction: row;
    justify-content: space-between;

    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    position: relative;
`;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    /* width: 110px; */
`;

export const LeftRef = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const SentTo = styled.div`
    margin-bottom: 5.5px;
    width: 95px;
    height: 11px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;

    /* identical to box height */
    text-align: center;
    text-transform: uppercase;

    color: #000000;
`;

export const Chevron = styled.button`
    margin-top: 5.5px;
    color: #6f747e;
    font-size: 24px;
    line-height: 11px;
    border: 0;
    padding: 0;
    background: transparent;
`;

export const IconWrapper = styled.button<{ active?: boolean }>`
    width: 40px;
    height: 40px;

    background: ${({ active }) => (active ? '#f37749' : ' #e6e7ec')};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UserAvatar = styled.img`
    width: 39px;
    height: 39px;
    border-radius: 50px;
`;

export const CalendarIcon = styled.img`
    width: 16px;
    height: 16px;
    filter: invert(48%) sepia(6%) saturate(560%) hue-rotate(182deg)
        brightness(93%) contrast(93%);
`;

export const InvoiceIcon = styled.img`
    width: 14px;
    height: 16px;
    filter: invert(48%) sepia(6%) saturate(560%) hue-rotate(182deg)
        brightness(93%) contrast(93%);
`;

export const Icon = styled.em`
    color: #6f747e;
`;

export const SmallCardText = styled.div<{ large?: boolean }>`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: ${({ large }) => (large ? 'bold' : 'normal')};
    font-size: ${({ large }) => (large ? 13 : 16)}px;

    display: flex;
    align-items: center;

    color: ${({ large }) => (large ? '#000000' : '#6f747e')};
`;

export const SmallCardInput = styled.input`
    margin-left: -19.5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    display: flex;
    align-items: center;

    color: #000000;

    border: 0;
    padding: 0;
    outline: none;
    background: transparent;
    max-width: 240px;
`;

export const CurrencyInput = styled.input`
    margin-left: -19.5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    height: 24px;

    display: flex;
    align-items: center;

    color: ${({ value }) => (value ? '#202531' : '#6f747e')};

    border: 0;
    padding: 0;
    outline: none;

    max-width: 240px;
    background: transparent;

    vertical-align: middle;
`;

export const InvoiceNumberInput = styled.input`
    margin-left: -19.5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    display: flex;
    align-items: center;

    color: ${({ value }) => (value ? '#202531' : '#6f747e')};

    border: 0;
    padding: 0;
    outline: none;

    max-width: 240px;
    background: transparent;

    height: 24px;
    vertical-align: middle;
`;

export const UserEmail = styled.div`
    margin-top: 2px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;

    color: #6f747e;
`;

export const InvoiceDate = styled.div`
    margin-left: -19.5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;

    color: #6f747e;
`;

export const SmallCard = styled.div<{ active?: boolean }>`
    position: relative;

    width: 360px;
    height: 100px;

    background: ${({ active }) => (active ? 'white' : ' #fbfbfb')};
    border: 1px solid #eaecf0;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 7.5px;

    &:hover {
        background-color: white;
    }

    &:hover ${IconWrapper} {
        background: #f37749;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 5px 8px rgba(113, 119, 129, 0.122);
    }

    ${IconWrapper} {
        background: ${({ active }) => (active ? '#f37749' : ' #e6e7ec')};
        border: ${({ active }) => (active ? 2 : 0)}px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px ${({ active }) => (active ? 5 : 0)}px
            ${({ active }) => (active ? 8 : 0)}px rgba(113, 119, 129, 0.122);
    }
    &:hover ${Icon} {
        color: white;
    }
    &:hover ${CalendarIcon} {
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(101deg)
            brightness(101%) contrast(101%);
    }

    ${CalendarIcon} {
        filter: ${({ active }) =>
            active
                ? 'invert(100%) sepia(0%) saturate(2%) hue-rotate(101deg) brightness(101%) contrast(101%)'
                : 'invert(48%) sepia(6%) saturate(560%) hue-rotate(182deg) brightness(93%) contrast(93%)'};
    }

    &:hover ${InvoiceIcon} {
        filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(101deg)
            brightness(101%) contrast(101%);
    }

    ${InvoiceIcon} {
        filter: ${({ active }) =>
            active
                ? 'invert(100%) sepia(0%) saturate(2%) hue-rotate(101deg) brightness(101%) contrast(101%)'
                : 'invert(48%) sepia(6%) saturate(560%) hue-rotate(182deg) brightness(93%) contrast(93%)'};
    }

    &:hover ${InvoiceDate} {
        color: #f37749;
    }

    ${InvoiceDate} {
        color: ${({ active }) => (active ? '#091551' : '#6f747e')};
    }

    ${Icon} {
        color: ${({ active }) => (active ? 'white' : '#6f747e')};
    }

    &:hover ${Chevron} {
        color: #f37749;
    }

    ${Chevron} {
        color: ${({ active }) => (active ? '#f37749' : '#6f747e')};
    }

    &:hover ${CurrencyInput} {
        color: #f37749;
    }
`;

export const Description = styled.input<{ active?: boolean }>`
    background: ${({ active }) => (active ? 'white' : '#fbfbfb')};
    border: 1px solid #eaecf0;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: -moz-calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    width: -o-calc(100% - 20px);
    width: calc(100% - 20px);
    height: 100px;
    text-align: center;
    vertical-align: middle;

    font-family: Azo Sans
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    padding: 38px 0;
    &:focus {
        outline: none !important;
        border: 2px solid #f37749; 
    }
    &:active {
        background-color: white;
    }
    &:hover {
        background-color: white;
    }
`;

export const AttachDocumentContainer = styled.div<{ active?: boolean }>`
    background: ${({ active }) => (active ? 'white' : '#fbfbfb')};
    border: 1px solid #eaecf0;
    border-radius: 5px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: -moz-calc(100% - 20px);
    width: -webkit-calc(100% - 20px);
    width: -o-calc(100% - 20px);
    width: calc(100% - 20px);
    height: 64px;

    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;

    &:active {
        background-color: white;
    }
    &:hover {
        background-color: white;
    }
`;

export const Footer = styled.div`
    width: 100%;
    height: 73px;
    left: 0px;
    bottom: 0px;

    background: #ffffff;
    border-top: 1px solid #eaecf0;
    box-shadow: inset 0px -1px 0px #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ListWrapper = styled.div`
    position: absolute;
    left: 45px;
    bottom: 7.5px;
    z-index: 999;
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    width: 290px;

    background: #ffffff;
    border: 1px solid #e6e7ec;

    /* Dropdown */
    box-shadow: 0px 10px 20px rgba(83, 83, 119, 0.149);
    border-radius: 4px;
`;

export const UsersList = styled(List)<{ large?: boolean }>`
    height: ${({ large }) => (large ? 300 : 272)}px;
    scrollbar-color: #f37749 white;
`;

export const CurrencyList = styled(List)`
    max-height: 272px;
`;

export const CurrencyListScroll = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    scrollbar-color: #f37749 white;
    overflow-y: scroll;
    border-radius: 4px;
`;

export const CurrencySearch = styled.div`
    margin-top: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #eaecf0;
    input {
        font-family: Azo Sans
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        border: 0;
        height: 33px;
        width: 100%;
        padding-left: 29px;
        :focus {
            outline: none;
        }
    }
`;

export const CalendarModal = styled(List)`
    .react-calendar {
        margin-top: 0px;
        border: none;
        box-shadow: none;
    }
`;

export const UserListWrapper = styled.div`
    position: relative;
    padding: 4px 0px 0px;
    /* height: -moz-calc(100% - 58px);
    height: -webkit-calc(100% - 58px);
    height: -o-calc(100% - 58px);
    height: calc(100% - 58px); */
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    /* scrollbar-color: #3367d6 #e1e1e1; */
`;

export const ItemContainer = styled.div<{ checked?: boolean }>`
    width: 100%;
    min-height: 42px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    background: ${({ checked }) => (checked ? '#F1F3F8' : 'white')};
`;

export const ItemContent = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2px;
    flex-direction: row;
`;

export const ItemAvatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 999px;

    margin-right: 12px;
`;

export const ItemAvatarContainer = styled.div<{ large?: boolean }>`
    min-width: ${({ large }) => (large ? 38 : 24)}px;
    height: ${({ large }) => (large ? 38 : 24)}px;

    background: ${({ large }) => (large ? '#519AFF' : 'white')};
    border: ${({ large }) => (large ? 0 : 1)}px solid #e6edfb;
    border-radius: 38px;

    display: flex;

    justify-content: center;

    margin-right: ${({ large }) => (large ? 0 : 12)}px;
`;

export const ItemAvatarTitle = styled.div<{ large?: boolean }>`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: ${({ large }) => (large ? 14 : 12)}px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.408px;

    color: ${({ large }) => (large ? 'white' : '#f37749')};
`;

export const PersonWrapper = styled.div<{ margin?: boolean }>`
    margin-left: ${({ margin }) => (margin ? -19.5 : 8)}px;
`;

export const ItemPerson = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;

    color: #000000;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

export const ItemEmail = styled.div`
    margin-top: 2px;

    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;

    color: #6f747e;
`;

export const CurrencyCode = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;

    /* identical to box height, or 123% */

    color: #000000;
`;

export const AttachmentContainer = styled.div`
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
`;

export const Attachment = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
`;

export const AttachmentText = styled.div`
    margin-left: 6px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;

    color: #000000;
    margin-bottom: -2px;
`;

export const AttachmentName = styled(AttachmentText)`
    max-width: 450px;
`;

export const DeleteAttachmentButton = styled.button`
    flex-direction: row;
    align-items: center;
    display: flex;
    border: 0px;
    padding: 0px;
    background: transparent;
    cursor: pointer;
    &:hover ${AttachmentText} {
        color: #f37749;
        font-weight: bold;
    }
    em {
        font-size: 18px;
        color: #555555;
    }
    &:hover em {
        color: #f37749;
    }
`;

export const FactoringButtonWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 105%;
`;

export const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const UserListFooter = styled.div`
    position: absolute;
    width: 100%;
    height: 58px;
    left: 0px;
    bottom: 0px;
    background: #fbfbfb;
    border-top: 1px solid #e6e7ec;
    border-radius: 0px 0px 4px 4px;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
`;

export const DidNtFind = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #000000;
`;

export const InviteIconSmall = styled.img`
    width: 48px;
    height: 48px;
    margin-top: 29px;
`;

export const InviteIcon = styled.img`
    width: 81px;
    height: 81px;
    margin-top: 35px;
`;

export const InviteHeaderSmall = styled.div`
    margin-top: 20px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
`;

export const InviteHeader = styled.div`
    margin-top: 20px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
`;

export const InviteSubtitleSmall = styled.div`
    margin-top: 5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    /* or 138% */
    text-align: center;
    color: #464646;
    margin: 5px 0px;
    padding-left: 25px;
    padding-right: 25px;
`;

export const InviteSubtitle = styled.div<{ red?: boolean }>`
    margin-top: 5px;
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* or 150% */
    text-align: center;
    color: ${({ red }) => (red ? 'rgb(181, 44, 44)' : '#464646')};
    padding-left: 65px;
    padding-right: 65px;
`;

export const InviteEmailWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 22px;
    position: static;
    min-width: 142px;
    height: 35px;
    left: 49px;
    top: 178px;
    background: #ffffff;
    border: 1px dashed #98b2ea;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 27px;
`;

export const InviteEmailSmall = styled.div`
    font-family: Azo Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    color: #000000;
    text-transform: lowercase;
`;

export const InvitationFormContainer = styled.div`
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
`;
