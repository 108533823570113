import ContentLoader from 'react-content-loader';

type Props = {
    id?: number;
    recipient?: string;
};

export const TdRecipient = ({ id, recipient }: Props) => {
    return (
        <td className="nowrap">
            <button type="button" style={{ minWidth: 80 }}>
                <div className="table-user-compact">
                    {!!recipient && !!id ? (
                        <>
                            <div className="table-user-media">
                                <div className="table-user-media-abbr">
                                    {/* {recipient} */}
                                </div>
                            </div>

                            <div className="table-user-name">
                                {recipient || ''}
                            </div>
                        </>
                    ) : !id ? (
                        <div
                            style={{
                                width: 80,
                                height: 18,
                                alignSelf: 'center',
                            }}
                        >
                            <ContentLoader viewBox="0 0 80 18">
                                <rect
                                    x="0"
                                    y="0"
                                    rx="9"
                                    ry="9"
                                    width="18"
                                    height="18"
                                />
                                <rect
                                    x="25"
                                    y="2.5"
                                    rx="2"
                                    ry="2"
                                    width="55"
                                    height="13"
                                />
                            </ContentLoader>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </button>
        </td>
    );
};
