import { AxiosRequestConfig, AxiosResponse } from 'axios';
import api from './axiosConfig';
import { fetchUser } from './UsersService';
import { countChainsAllSet } from '../state/collectionsCount';
import { store } from '../state/store';
import { chainsSet, chainsUpdate, chainUpdate } from '../state/chains';
import { reportUpdate } from '../state/reports';
import { PayChainByAchProps } from '../types/types';

// &extra

export const fetchChains = async (
    params?: AxiosRequestConfig['params'],
    update?: boolean,
) => {
    const response = await api('/chains', {
        params: {
            sortField: 'created_at',
            sortOrder: 'DESC',
            per_page: 12,
            ...params,
        },
    });
    if (response?.status === 200 && !!response?.data?.data) {
        store.dispatch(countChainsAllSet(response.data?.meta?.total));
        if (!update) {
            store.dispatch(chainsSet(response.data.data));
        } else {
            store.dispatch(chainsUpdate(response.data?.data));
        }
    }
    return response;
};

export const fetchChain = async (id: number) => {
    const response = await api(`/chains/${id}`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(chainUpdate(response.data?.data));
    }
    return response;
};

export const acceptChain = async (id: number) => {
    const response = await api.patch(`/chains/${id}/accept`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(chainUpdate(response.data?.data));
    }
    return response;
};

export const rejectChain = async (id: number) => {
    const response = await api.patch(`/chains/${id}/reject`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(chainUpdate(response.data?.data));
    }
    return response;
};

export const payChain: Promise<AxiosResponse<any>> | any = async (id: number) =>
    await api.patch(`/chains/${id}/pay`);

export const payChainFromBalance: Promise<AxiosResponse<any>> | any = async (
    id: number,
) => {
    const response = await api.patch(`/chains/${id}/pay-from-balance`);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(reportUpdate(response.data?.data));
        await fetchUser();
    }
    return response;
};

export const payChainByAch: Promise<AxiosResponse<any>> | any = async (
    id: number,
    props: PayChainByAchProps,
) => {
    const response = await api.patch(`/chains/${id}/pay-bank-account`, props);
    if (response.status === 200 && response.data?.data) {
        store.dispatch(reportUpdate(response.data?.data));
    }
    return response;
};
