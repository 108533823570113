import { useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import {
    FilterContainer,
    InputWrapper,
    SelectComponent,
} from '../../components';
import { TBalancePaymentHistory } from '../../types/types';

type Props = {
    isLoading?: boolean;

    // query?: string;
    status?: TBalancePaymentHistory['state'][];
    // paymentStatus?: Transaction['payment_status'][];

    // onQueryChange?: (value: string) => void;
    onStatusChange: (value: TBalancePaymentHistory['state'][] | []) => void;
};

export const FiltersHeader = ({
    isLoading,

    // query,
    status,

    // onQueryChange,
    onStatusChange,
}: Props) => {
    const statuses: TBalancePaymentHistory['state'][] = useMemo(() => {
        const array: TBalancePaymentHistory['state'][] = [
            'PENDING',
            'SUCCEEDED',
        ];

        return array;
    }, []);

    const statusOptions = useMemo(
        () =>
            statuses.map(s => ({
                value: s,
                label: s,
            })),
        [statuses],
    );

    const selectedStatusesOptions = useMemo(
        () =>
            status?.map(s => ({
                value: s,
                label: s,
            })),
        [status],
    );

    const onStatusChangeMethod = useCallback(
        (
            objArr:
                | {
                      value: TBalancePaymentHistory['state'];
                      label: TBalancePaymentHistory['state'];
                  }[]
                | [],
        ) => {
            onStatusChange(objArr?.map(item => item?.value));
        },
        [onStatusChange],
    );

    return (
        <FilterContainer className="form">
            <>
                {/* <InputWrapper>
                    <div className="form-control-wrapper">
                        <SearchIcon src={IconSearch} />
                        <input
                            id="query"
                            className="form-control"
                            placeholder="Search"
                            value={query || ''}
                            onChange={e => onQueryChange(e.target.value)}
                            style={{
                                paddingLeft: 29,
                                paddingRight: 29,
                                width: 187,
                            }}
                        />
                        <div className="form-control-info">
                            <em className="mdi mdi-information form-control-info-icon" />
                            <div className="form-control-info-container form-control-info-container-dim">
                                Search by amount due OR organization name
                                <br />
                            </div>
                        </div>
                    </div>
                </InputWrapper> */}

                <InputWrapper>
                    <SelectComponent
                        isMulti
                        isClearable
                        placeholder="Payment state"
                        value={selectedStatusesOptions}
                        onChange={onStatusChangeMethod}
                        options={statusOptions}
                    />
                </InputWrapper>
            </>

            {!!isLoading && (
                <Loader
                    type="ThreeDots"
                    color="#f37749"
                    visible
                    height={20}
                    width={50}
                />
            )}
        </FilterContainer>
    );
};
