import { Mdi, PayMethodButton, PayMethodContainer } from './styles';

type Props = {
    isCanPayFromBalance?: boolean;
    payByStripe?: () => void;
    payFromBalance: () => Promise<void>;
};

export const PayMethodButtons = ({
    isCanPayFromBalance,
    payByStripe,
    payFromBalance,
}: Props) => {
    return (
        <PayMethodContainer big={!!isCanPayFromBalance}>
            {!!payByStripe && (
                <PayMethodButton
                    first
                    role="button"
                    tabIndex={0}
                    onClick={payByStripe}
                    onKeyDown={payByStripe}
                >
                    <Mdi className="mdi mdi-credit-card-outline" />
                    Credit card
                </PayMethodButton>
            )}
            <PayMethodButton
                role="button"
                last={!isCanPayFromBalance}
                disabled
                tabIndex={-1}
            >
                <Mdi className="mdi mdi-square-edit-outline" />
                SEPA Payment
            </PayMethodButton>
            {!!isCanPayFromBalance && (
                <PayMethodButton
                    role="button"
                    last
                    tabIndex={-2}
                    onClick={payFromBalance}
                    onKeyDown={payFromBalance}
                >
                    <Mdi className="mdi mdi-credit-card-outline" />
                    Via LBBV Bank integration
                </PayMethodButton>
            )}
        </PayMethodContainer>
    );
};
