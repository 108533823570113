import { useSelector } from 'react-redux';
import { Button, ButtonsContainer, Icon } from './styles';
import ptpIcon from '../../../assets/img/icons/icon-ptp.svg';
import invoiceIcon from '../../../assets/img/icons/icon-invoice.svg';
import { selectIsIndividual } from '../../../state/user';

type Props = {
    createPtp?: () => void;
    createInvoice?: () => void;
    disabled?: boolean;
};

export const TransactionButtons = ({
    createPtp,
    createInvoice,
    disabled,
}: Props) => {
    const isIndividual = useSelector(selectIsIndividual);
    return !disabled ? (
        <ButtonsContainer>
            <Button
                key={1}
                first
                role="button"
                tabIndex={0}
                onClick={createPtp}
                onKeyDown={createPtp}
            >
                <Icon src={ptpIcon} />
                Create PTP
            </Button>

            {!isIndividual && (
                <Button
                    key={2}
                    last
                    role="button"
                    tabIndex={-1}
                    onClick={createInvoice}
                    onKeyDown={createInvoice}
                >
                    <Icon src={invoiceIcon} />
                    Create invoice
                </Button>
            )}
        </ButtonsContainer>
    ) : null;
};
