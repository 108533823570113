import ContentLoader from 'react-content-loader';
import { amountOptions } from '../../services/helpers';

type Props = {
    id?: number;
    icon?: string;
    amount?: number;
};

export const TdAmount = ({ id, icon, amount }: Props) => {
    return (
        <td className="nowrap">
            <button type="button">
                {id ? (
                    <div className="table-currency">
                        <div className="table-currency-media">
                            {!!icon && (
                                <img
                                    className="table-currency-media-image"
                                    src={icon}
                                    alt="currency icon"
                                />
                            )}
                        </div>
                        <div className="table-currency-value">
                            {amount || amount === 0
                                ? amount?.toLocaleString('en', amountOptions)
                                : ''}
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            width: 59,
                            height: 18,
                            alignSelf: 'center',
                        }}
                    >
                        <ContentLoader viewBox="0 0 59 18">
                            <rect
                                x="0"
                                y="0"
                                rx="9"
                                ry="9"
                                width="18"
                                height="18"
                            />
                            <rect
                                x="24"
                                y="2.5"
                                rx="2"
                                ry="2"
                                width="35"
                                height="13"
                            />
                        </ContentLoader>
                    </div>
                )}
            </button>
        </td>
    );
};
