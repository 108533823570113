import { DateTime } from 'luxon';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Calendar from 'react-calendar';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AttachButton } from './AttachButton';
import { CurrencyItem } from './CurrencyItem';
import { InvitationForm } from './InvitationForm';
import {
    AttachDocumentContainer,
    Attachment,
    AttachmentContainer,
    AttachmentName,
    AttachmentText,
    CalendarIcon,
    CalendarModal,
    Center,
    Chevron,
    CloseButton,
    CloseButtonText,
    Content,
    CurrencyInput,
    CurrencyList,
    CurrencyListScroll,
    CurrencySearch,
    DeleteAttachmentButton,
    Description,
    Footer,
    Header,
    HeaderTitle,
    Icon,
    IconWrapper,
    InviteEmailSmall,
    InviteEmailWrapper,
    InviteHeader,
    InviteHeaderSmall,
    InviteIcon,
    InviteIconSmall,
    InviteSubtitle,
    InviteSubtitleSmall,
    InvoiceDate,
    InvoiceIcon,
    InvoiceNumberInput,
    ItemAvatarContainer,
    ItemAvatarTitle,
    Left,
    LeftRef,
    ListWrapper,
    PersonWrapper,
    RowElement,
    SentTo,
    SmallCard,
    SmallCardInput,
    SmallCardText,
    UserAvatar,
    UserEmail,
    UserListWrapper,
    UsersList,
} from './styles';
import { UserItem } from './UserItem';
import flagEU from '../../assets/img/currency-icons/eur.svg';
import calendarIcon from '../../assets/img/icons/calendar.svg';
import iconSearch from '../../assets/img/icons/icon-search.svg';
import inviteIcon from '../../assets/img/icons/invite-icon.svg';
import invoiceIcon from '../../assets/img/icons/invoice.svg';
import {
    FooterButtons,
    FormError,
    Row,
    SearchIcon,
    Spinner,
} from '../../components';
import { useComponentVisible } from '../../hooks';
import {
    comaNumber,
    desc,
    getInitials,
    hideEmail,
    isEmail,
    number,
    removeSnake,
} from '../../services/helpers';
import {
    fetchCurrentOrganizationStatistics,
    fetchOrganizations,
    inviteOrganization,
} from '../../services/OrganizationsService';
import { createTransaction } from '../../services/TransactionsService';
import { getCurrencies } from '../../state/currencies';
import { getOrganizations } from '../../state/organizations';
import { getDefaultCurrency } from '../../state/user';
import {
    Currency,
    InvitationProps,
    Organization,
    OrganizationsState,
} from '../../types/types';
import './Calendar.css';
import { uploadFile } from '../../services/FilesService';
import { fetchCurrencies } from '../../services/CurrenciesService';
import { getMyCurrentOrganizationId } from '../../state/statistics';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 750,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type Props = {
    type: 'ptp' | 'invoice';
    isModalOpen: boolean;
    onRequestClose: () => void;
};

const sort = (organizations: OrganizationsState): Organization[] => {
    const organizationsArray: Organization[] = Object.values(organizations);
    organizationsArray.sort((a, b) =>
        desc(
            DateTime.fromSQL(a.created_at).valueOf(),
            DateTime.fromSQL(b.created_at).valueOf(),
        ),
    );
    return organizationsArray;
};

const allFieldsInvitation = [
    'first_name',
    'last_name',
    'company_name',
    'email',
    'country_code',
];

const requiredFieldsInvitation = allFieldsInvitation;

export const CreateTransactionModal = ({
    type,
    isModalOpen,
    onRequestClose,
}: Props) => {
    const organizationsState = useSelector(getOrganizations);

    const myCurrentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const organizations = useMemo(() => sort(organizationsState), [
        organizationsState,
    ]);

    const [checkedMember, setCheckedMember] = useState<Organization>();

    const currencies = useSelector(getCurrencies);
    useEffect(() => {
        if (!currencies?.length) {
            (async () => {
                await fetchCurrencies();
            })();
        } else {
            setCurrenciesList(currencies);
        }
    }, [currencies, currencies?.length]);

    const defaultCurrency = useSelector(getDefaultCurrency);

    const [checkedCurrency, setCheckedCurrency] = useState<Currency>(
        defaultCurrency,
    );

    useEffect(() => {
        if (defaultCurrency?.id) {
            setCheckedCurrency(defaultCurrency);
        }
    }, [defaultCurrency]);

    const [query, setQuery] = useState<string>();

    const [meta, setMeta] = useState({ current_page: 0, last_page: 1 });

    const [isOrganizationsLoading, setOrganizationsLoading] = useState(false);

    const fetchSomeOrganizations = useCallback(
        async (currentPage = 0, lastPage = 1) => {
            const page = currentPage + 1;
            if (currentPage < lastPage) {
                setOrganizationsLoading(true);
                const organizationsResponse = await fetchOrganizations({
                    page,
                    per_page: 20,
                    q: query,
                });
                setOrganizationsLoading(false);
                if (organizationsResponse?.data?.meta) {
                    setMeta(organizationsResponse?.data?.meta);
                }
            }
        },
        [query],
    );

    useEffect(() => {
        if (myCurrentOrganizationId && isModalOpen) {
            setCheckedMember(undefined);
            (async () => {
                await fetchSomeOrganizations();
            })();
        }
    }, [fetchSomeOrganizations, isModalOpen, myCurrentOrganizationId]);

    const loadMoreUsers = useCallback(async () => {
        await fetchSomeOrganizations(meta.current_page, meta.last_page);
    }, [fetchSomeOrganizations, meta.current_page, meta.last_page]);

    const [inviteEmail, setInviteEmail] = useState<string>();

    const [isInvitationFormVisible, setInvitationFormVisible] = useState(false);

    const userListHook = useComponentVisible(false);

    const showInvitationForm = useCallback(() => {
        setInviteEmail(undefined);
        userListHook?.setIsComponentVisible(false);
        setInvitationFormVisible(true);
    }, [userListHook]);

    const [invitationFields, setInvitationFields] = useState<
        Partial<InvitationProps>
    >({});

    const [invitationErrors, setInvitationErrors] = useState<{
        [key: string]: any;
    }>({});

    const invitationFieldsChanged = useCallback(
        (key: keyof InvitationProps, value: string) => {
            setInvitationFields(prevState => ({
                ...prevState,
                [key]: value,
            }));
            if (!value) {
                const errorMessage = `The ${removeSnake(
                    key,
                )} field is required`;

                if (!invitationErrors.hasOwnProperty(key))
                    setInvitationErrors(prevState => {
                        return { ...prevState, [key]: [errorMessage] };
                    });
            } else {
                setInvitationErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [key]: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [invitationErrors],
    );

    const closeInvitationForm = useCallback(() => {
        setInviteEmail(undefined);
        setInvitationFields({});
        setInvitationErrors({});
        setInvitationFormVisible(false);
        userListHook?.setIsComponentVisible(false);
    }, [userListHook]);

    const search = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setUserInvited(false);
            const value = e?.target?.value;
            const delayDebounceFn = setTimeout(() => {
                setQuery(value);
                if (value) {
                    if (!organizations?.length && isEmail(value)) {
                        setInviteEmail(value);
                    }
                    if (!isEmail(value)) {
                        setInviteEmail(undefined);
                    }
                } else {
                    setInviteEmail(undefined);
                }
            }, 350);
            invitationFieldsChanged('email', value);
            if (value && value?.length > 0 && !checkedMember) {
                userListHook?.setIsComponentVisible(true);
            }
            return () => clearTimeout(delayDebounceFn);
        },
        [
            checkedMember,
            invitationFieldsChanged,
            organizations?.length,
            userListHook,
        ],
    );

    const [isUserInvited, setUserInvited] = useState(false);
    const [inviteLoader, setInviteLoader] = useState(false);

    const checkEmptyInvitationFields = useCallback(() => {
        let allFieldsFilled = true;
        requiredFieldsInvitation.forEach(item => {
            if (
                !invitationErrors.hasOwnProperty(item) &&
                (!invitationFields?.hasOwnProperty(item) ||
                    invitationFields[item] === '')
            ) {
                allFieldsFilled = false;
                const errorMessage = `The ${removeSnake(
                    item,
                )} field is required`;
                setInvitationErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [invitationErrors, invitationFields]);

    const onOrganizationSelect = useCallback((organization: Organization) => {
        setCheckedMember(organization);
        setUserInputShown(false);
    }, []);

    const invite = useCallback(async () => {
        let delayDebounceFn: NodeJS.Timeout | undefined;
        if (!Object.keys(invitationErrors).length) {
            const allFieldsFilled = checkEmptyInvitationFields();
            if (allFieldsFilled) {
                setInviteLoader(true);
                const response = await inviteOrganization(invitationFields);
                setInviteLoader(false);

                if (
                    (response.status === 200 || response.status === 201) &&
                    response?.data?.data
                ) {
                    setUserInvited(true);
                    delayDebounceFn = setTimeout(async () => {
                        await fetchOrganizations({
                            q: invitationFields?.email,
                        });
                        onOrganizationSelect(response?.data?.data);
                        setInviteEmail(undefined);
                        setInvitationFields({});
                        setUserInvited(false);
                        closeInvitationForm();
                    }, 1000);
                }
                if (
                    response?.response?.status === 422 &&
                    response?.response?.data?.errors
                ) {
                    const allErrors = response?.response?.data?.errors;
                    const filteredErrors = Object.keys(allErrors)
                        .filter(key => allFieldsInvitation.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});

                    const otherFilteredErrors = Object.keys(allErrors)
                        .filter(key => !allFieldsInvitation.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});
                    setInvitationErrors(filteredErrors);
                    console.log('otherFilteredErrors', otherFilteredErrors);
                }
            }
        }
        return () => {
            if (delayDebounceFn) clearTimeout(delayDebounceFn);
        };
    }, [
        checkEmptyInvitationFields,
        closeInvitationForm,
        invitationErrors,
        invitationFields,
        onOrganizationSelect,
    ]);

    const inputRefUser = useRef<HTMLInputElement>(null);

    const [isUserInputShown, setUserInputShown] = useState(false);

    const showUserInput = useCallback(() => {
        setCheckedMember(undefined);
        setUserInputShown(true);
    }, []);

    useEffect(() => {
        if (isUserInputShown) {
            if (inputRefUser?.current) {
                setCheckedMember(undefined);
                inputRefUser?.current?.focus();
            }
        }
    }, [isUserInputShown]);

    const handleUsersScroll = useCallback(
        async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            const bottom =
                e.currentTarget.scrollHeight - e.currentTarget.scrollTop - 2 <
                e.currentTarget.clientHeight;

            if (bottom && !isOrganizationsLoading) {
                await loadMoreUsers();
            }
        },
        [isOrganizationsLoading, loadMoreUsers],
    );

    const displayUsersListMethod = useCallback(() => {
        userListHook?.setIsComponentVisible(prevState => !prevState);
    }, [userListHook]);

    const inputRefCurrency = useRef<HTMLInputElement>(null);

    const currencyListHook = useComponentVisible(false);

    const [amount, setAmount] = useState<string>('');

    const amountPlaceHolder = useMemo(
        () =>
            checkedCurrency?.code === 'BTC' || checkedCurrency?.code === 'ETH'
                ? '00.0000000'
                : '00.00',
        [checkedCurrency?.code],
    );

    const onAmountChange = useCallback(
        (e: any, cc?: string) => {
            const numberAmount = comaNumber(
                e?.target?.value,
                cc === 'BTC' ||
                    cc === 'ETH' ||
                    checkedCurrency?.code === 'BTC' ||
                    checkedCurrency?.code === 'ETH'
                    ? 8
                    : 4,
            );
            setAmount(numberAmount);
        },
        [checkedCurrency?.code],
    );

    const onCurrencySelect = useCallback(
        (currency: Currency) => {
            setCheckedCurrency(currency);
            onAmountChange(
                {
                    target: {
                        value: amount,
                    },
                },
                currency?.code,
            );
            currencyListHook?.setIsComponentVisible(false);
        },
        [amount, currencyListHook, onAmountChange],
    );

    const displayCurrencyListMethod = useCallback(() => {
        currencyListHook?.setIsComponentVisible(prevState => !prevState);
    }, [currencyListHook]);

    const dateListHook = useComponentVisible(false);

    const [date, setDate] = useState<Date | Date[] | null | undefined>();

    const onDateChange = useCallback(
        d => {
            setDate(d);
            dateListHook?.setIsComponentVisible(false);
        },
        [dateListHook],
    );

    const displayDateListMethod = useCallback(() => {
        dateListHook?.setIsComponentVisible(prevState => !prevState);
    }, [dateListHook]);

    const inputRefInvoiceNumber = useRef<HTMLInputElement>(null);

    const [invoiceNumber, setInvoiceNumber] = useState<string>('');

    const onInvoiceNumberChange = useCallback(e => {
        const invoiceNumberValue = e?.target?.value;
        setInvoiceNumber(invoiceNumberValue);
    }, []);

    const focusInvoiceNumber = useCallback(() => {
        if (inputRefInvoiceNumber?.current) {
            inputRefInvoiceNumber?.current?.focus();
        }
    }, []);

    const inputRefDescription = useRef<HTMLInputElement>(null);

    const [description, setDescription] = useState<string>('');

    const onDescriptionChange = useCallback(e => {
        setDescription(e?.target?.value);
    }, []);

    const [isLoading, setLoading] = useState(false);

    const [attachment, setAttachment] = useState<{
        name: string;
        file: string;
    }>();

    const onClose = useCallback(() => {
        setCheckedMember(undefined);
        setCheckedCurrency(defaultCurrency);
        setAmount('');
        setDate(undefined);
        setInvoiceNumber('');
        setDescription('');
        setSearchOption('');
        setCurrenciesList(currencies);
        onRequestClose();
        setQuery('');
        setErrors({});
        closeInvitationForm();
    }, [closeInvitationForm, currencies, defaultCurrency, onRequestClose]);

    const [errors, setErrors] = useState<{ [key: string]: any }>({});

    const history = useHistory();

    const onSubmit = useCallback(async () => {
        setErrors({});
        if (
            checkedMember?.id &&
            checkedCurrency?.id &&
            amount &&
            !!date &&
            !(date instanceof Array)
        ) {
            setLoading(true);
            let fileUid;
            if (attachment?.file) {
                const uploadNewFile = await uploadFile(
                    attachment?.file as any,
                    'transaction_file',
                );
                if (uploadNewFile?.status === 201) {
                    fileUid = uploadNewFile?.data?.data?.uid;
                } else {
                    alert(
                        'Accepted extensions: .pdf, .doc, .docx, .jpg, .jpeg, .png. Maximum file size - 4MB',
                    );
                }
            }
            const transactionResponse = await createTransaction({
                to_organization_id: checkedMember?.id,
                amount: number(amount),
                transaction_date: DateTime.fromJSDate(date).toFormat(
                    'yyyy-MM-dd',
                ),
                currency_id: checkedCurrency?.id,
                transaction_number: invoiceNumber,
                description,
                type,
                file_uid: fileUid,
            });
            setLoading(false);
            if (
                transactionResponse.status === 201 ||
                transactionResponse.status === 200
            ) {
                await fetchCurrentOrganizationStatistics();
                onClose();
                if (transactionResponse?.data?.data?.id) {
                    history.push(
                        `/transactions/transaction/${
                            transactionResponse?.data?.data?.id
                        }/${'new'}`,
                    );
                }
            } else if (transactionResponse?.response?.data?.errors) {
                setErrors(transactionResponse?.response?.data?.errors);
            }
        }
    }, [
        amount,
        attachment?.file,
        checkedCurrency?.id,
        checkedMember?.id,
        date,
        description,
        history,
        invoiceNumber,
        onClose,
        type,
    ]);

    const openFile = useCallback(e => {
        if (FileReader && e.target?.files && e.target?.files.length) {
            const files = [...e.target?.files];
            const fr = new FileReader();
            fr.readAsDataURL(files[0]);
            console.log(files[0]);
            fr.onload = () => {
                setAttachment({ name: files[0]?.name, file: files[0] });
            };
        }
        e.target.value = null;
    }, []);

    const deleteAttachment = useCallback(() => {
        setAttachment(undefined);
    }, []);

    const [currenciesList, setCurrenciesList] = useState(currencies);

    const [searchOption, setSearchOption] = useState<string>('');

    const searchFilterFunction = useCallback(
        (text: string) => {
            setSearchOption(text);
            if (!text || text === '') {
                setCurrenciesList(currencies || []);
            } else {
                let filteredList = [...(currencies || [])];
                filteredList = filteredList.filter(item => {
                    const itemData = `${item.code.toUpperCase()} ${item.name.toUpperCase()}}`;
                    const textData = text.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                });
                setCurrenciesList(filteredList);
            }
        },
        [currencies],
    );

    return (
        <Modal
            isOpen={isModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Create transaction"
        >
            <Header>
                <HeaderTitle>{`Create ${
                    type === 'ptp' ? 'promise to pay' : 'invoice'
                }`}</HeaderTitle>
                <CloseButton onClick={onClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>

            <Content>
                {isInvitationFormVisible ? (
                    <Center>
                        <InviteIcon src={inviteIcon} alt="invite-icon" />
                        <InviteHeader>
                            {!isUserInvited ? 'Send invite' : 'The user was'}
                        </InviteHeader>
                        <InviteSubtitle
                            red={
                                organizations?.length === 1 &&
                                !!invitationFields?.email &&
                                !!isEmail(invitationFields?.email)
                            }
                        >
                            {organizations?.length === 1 &&
                            !!invitationFields?.email &&
                            !!isEmail(invitationFields?.email)
                                ? 'This user already invited. You can make with him transaction'
                                : !isUserInvited
                                ? 'We did not find such a user, invite your business colleague to the Troc Circle'
                                : 'successfully invited'}
                        </InviteSubtitle>
                        {!isUserInvited && (
                            <InvitationForm
                                invitationFields={invitationFields}
                                invitationErrors={invitationErrors}
                                invitationFieldsChanged={
                                    invitationFieldsChanged
                                }
                                search={search}
                            />
                        )}
                        {!!inviteLoader && <Spinner />}
                    </Center>
                ) : (
                    <>
                        <RowElement>
                            <SmallCard
                                active={
                                    userListHook?.isComponentVisible ||
                                    !!checkedMember?.id
                                }
                            >
                                <Left>
                                    <SentTo>
                                        {checkedMember?.id ? 'Send to:' : ''}
                                    </SentTo>
                                    <LeftRef ref={userListHook?.buttonRef}>
                                        <IconWrapper
                                            onClick={displayUsersListMethod}
                                        >
                                            {checkedMember?.id ? (
                                                checkedMember?.logo?.sizes
                                                    ?.thumbnail ? (
                                                    <UserAvatar
                                                        src={
                                                            checkedMember?.logo
                                                                ?.sizes
                                                                ?.thumbnail
                                                        }
                                                        alt="company logo"
                                                    />
                                                ) : (
                                                    <ItemAvatarContainer large>
                                                        <ItemAvatarTitle large>
                                                            {checkedMember?.company_name &&
                                                                getInitials(
                                                                    checkedMember?.company_name,
                                                                )}
                                                        </ItemAvatarTitle>
                                                    </ItemAvatarContainer>
                                                )
                                            ) : (
                                                <Icon className="mdi mdi-help" />
                                            )}
                                        </IconWrapper>
                                        <Chevron
                                            className={`mdi mdi-chevron-${
                                                userListHook?.isComponentVisible
                                                    ? 'up'
                                                    : 'down'
                                            }`}
                                            onClick={displayUsersListMethod}
                                        />
                                    </LeftRef>
                                </Left>

                                {!isUserInputShown ? (
                                    <PersonWrapper
                                        margin
                                        onClick={showUserInput}
                                    >
                                        <SmallCardText
                                            large={!!checkedMember?.id}
                                        >
                                            {checkedMember?.id
                                                ? checkedMember?.company_name
                                                : 'Select recipient organization'}
                                        </SmallCardText>
                                        <UserEmail>
                                            {!!checkedMember?.email &&
                                                hideEmail(checkedMember?.email)}
                                        </UserEmail>
                                    </PersonWrapper>
                                ) : (
                                    <SmallCardInput
                                        ref={inputRefUser}
                                        placeholder="Search or select "
                                        onChange={search}
                                    />
                                )}
                                <ListWrapper ref={userListHook?.ref}>
                                    {userListHook?.isComponentVisible && (
                                        <UsersList large={!!inviteEmail}>
                                            {inviteEmail ? (
                                                <>
                                                    <InviteIconSmall
                                                        src={inviteIcon}
                                                        alt="invite-icon"
                                                    />
                                                    <InviteHeaderSmall>
                                                        {!isUserInvited
                                                            ? 'We did not find such an organization'
                                                            : 'The organization was'}
                                                    </InviteHeaderSmall>
                                                    <InviteSubtitleSmall>
                                                        {!isUserInvited
                                                            ? 'Invite your business colleague to the Troc Circle'
                                                            : 'successfully invited'}
                                                    </InviteSubtitleSmall>
                                                    <InviteEmailWrapper>
                                                        <InviteEmailSmall>
                                                            {inviteEmail}
                                                        </InviteEmailSmall>
                                                    </InviteEmailWrapper>
                                                    {!isUserInvited && (
                                                        <button
                                                            type="button"
                                                            className="button button-primary button-sm"
                                                            style={{
                                                                width: 250,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                            onClick={
                                                                showInvitationForm
                                                            }
                                                        >
                                                            <em className="mdi mdi-account-plus-outline start" />
                                                            Send invite
                                                        </button>
                                                    )}
                                                    {!!inviteLoader && (
                                                        <Spinner />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <UserListWrapper
                                                        onScroll={
                                                            handleUsersScroll
                                                        }
                                                    >
                                                        {organizations?.map(
                                                            organization => (
                                                                <UserItem
                                                                    key={organization?.id?.toString()}
                                                                    person={
                                                                        organization?.company_name
                                                                    }
                                                                    avatar={
                                                                        organization
                                                                            ?.logo
                                                                            ?.sizes
                                                                            ?.thumbnail
                                                                    }
                                                                    email={
                                                                        organization?.email
                                                                    }
                                                                    checked={
                                                                        checkedMember?.id ===
                                                                        organization?.id
                                                                    }
                                                                    handleClick={() =>
                                                                        onOrganizationSelect(
                                                                            organization,
                                                                        )
                                                                    }
                                                                />
                                                            ),
                                                        )}
                                                    </UserListWrapper>
                                                    {/* <UserListFooter>
                                                        <DidNtFind>
                                                            Didn&apos;t find
                                                            anyone?
                                                        </DidNtFind>
                                                        <button
                                                            type="button"
                                                            className="button button-primary button-sm"
                                                            onClick={
                                                                showInvitationForm
                                                            }
                                                        >
                                                            <em className="mdi mdi-account-plus-outline start" />
                                                            Send invite
                                                        </button>
                                                    </UserListFooter> */}
                                                </>
                                            )}
                                        </UsersList>
                                    )}
                                </ListWrapper>
                            </SmallCard>
                            <SmallCard
                                active={
                                    currencyListHook?.isComponentVisible ||
                                    !!amount
                                }
                            >
                                <Left>
                                    <SentTo />
                                    <LeftRef
                                        ref={currencyListHook.buttonRef}
                                        onClick={displayCurrencyListMethod}
                                    >
                                        <IconWrapper>
                                            <UserAvatar
                                                src={
                                                    checkedCurrency?.icon
                                                        ?.svg || flagEU
                                                }
                                                alt="currency"
                                            />
                                        </IconWrapper>
                                        <Chevron
                                            className={`mdi mdi-chevron-${
                                                currencyListHook?.isComponentVisible
                                                    ? 'up'
                                                    : 'down'
                                            }`}
                                        />
                                    </LeftRef>
                                </Left>
                                <CurrencyInput
                                    ref={inputRefCurrency}
                                    placeholder={amountPlaceHolder}
                                    value={amount}
                                    onChange={onAmountChange}
                                />
                                <ListWrapper ref={currencyListHook?.ref}>
                                    {currencyListHook?.isComponentVisible && (
                                        <CurrencyList>
                                            <CurrencySearch>
                                                <SearchIcon src={iconSearch} />
                                                <input
                                                    id="search"
                                                    className="form-control"
                                                    placeholder="Search"
                                                    value={searchOption}
                                                    onChange={e =>
                                                        searchFilterFunction(
                                                            e.target.value,
                                                        )
                                                    }
                                                />
                                            </CurrencySearch>
                                            <CurrencyListScroll>
                                                {currenciesList?.map(
                                                    currency => (
                                                        <CurrencyItem
                                                            key={currency?.id?.toString()}
                                                            icon={
                                                                currency?.icon
                                                                    ?.svg
                                                            }
                                                            code={
                                                                currency?.code
                                                            }
                                                            checked={
                                                                checkedCurrency?.id ===
                                                                currency?.id
                                                            }
                                                            handleClick={() =>
                                                                onCurrencySelect(
                                                                    currency,
                                                                )
                                                            }
                                                        />
                                                    ),
                                                )}
                                            </CurrencyListScroll>
                                        </CurrencyList>
                                    )}
                                </ListWrapper>
                            </SmallCard>
                        </RowElement>
                        <RowElement>
                            <SmallCard
                                active={
                                    dateListHook?.isComponentVisible || !!date
                                }
                            >
                                <Row ref={dateListHook.buttonRef}>
                                    <Left>
                                        <SentTo />

                                        <IconWrapper>
                                            <CalendarIcon
                                                onClick={displayDateListMethod}
                                                src={calendarIcon}
                                                alt="calendar"
                                            />
                                        </IconWrapper>
                                        <Chevron
                                            onClick={displayDateListMethod}
                                            className={`mdi mdi-chevron-${
                                                dateListHook?.isComponentVisible
                                                    ? 'up'
                                                    : 'down'
                                            }`}
                                        />
                                    </Left>
                                    <InvoiceDate
                                        onClick={displayDateListMethod}
                                    >
                                        {(!!date &&
                                            !(date instanceof Array) &&
                                            DateTime.fromJSDate(date).toFormat(
                                                'dd.MM.yyyy',
                                            )) ||
                                            'Invoice date'}
                                    </InvoiceDate>
                                </Row>
                                <ListWrapper ref={dateListHook?.ref}>
                                    {dateListHook?.isComponentVisible && (
                                        <CalendarModal>
                                            <Calendar
                                                onChange={onDateChange}
                                                value={date}
                                            />
                                        </CalendarModal>
                                    )}
                                </ListWrapper>
                            </SmallCard>
                            <SmallCard
                                active={!!invoiceNumber}
                                onClick={focusInvoiceNumber}
                            >
                                <Left>
                                    <SentTo>
                                        {invoiceNumber ? 'Invoice number' : ''}
                                    </SentTo>
                                    <IconWrapper>
                                        <InvoiceIcon
                                            src={invoiceIcon}
                                            alt="invoice"
                                        />
                                    </IconWrapper>
                                    <SentTo />
                                </Left>
                                <InvoiceNumberInput
                                    ref={inputRefInvoiceNumber}
                                    placeholder="Invoice number"
                                    value={invoiceNumber}
                                    onChange={onInvoiceNumberChange}
                                />
                            </SmallCard>
                        </RowElement>
                        <Description
                            ref={inputRefDescription}
                            placeholder="Add description"
                            active={!!description}
                            value={description}
                            onChange={onDescriptionChange}
                        />
                        <AttachDocumentContainer active={!!attachment?.name}>
                            {!!attachment?.name && (
                                <AttachmentContainer>
                                    <Attachment>
                                        <em
                                            className="mdi mdi-attachment start"
                                            style={{
                                                transform: 'rotate(-45deg)',
                                                fontSize: 18,
                                                color: '#f37749',
                                            }}
                                        />
                                        <AttachmentName>
                                            {attachment?.name}
                                        </AttachmentName>
                                    </Attachment>
                                    <DeleteAttachmentButton
                                        onClick={deleteAttachment}
                                    >
                                        <em className="mdi mdi-delete-outline start" />
                                        <AttachmentText>Delete</AttachmentText>
                                    </DeleteAttachmentButton>
                                </AttachmentContainer>
                            )}
                            {!attachment?.name && (
                                <AttachButton onPress={openFile} />
                            )}
                        </AttachDocumentContainer>
                        {!!Object.entries(errors)?.length && (
                            <div
                                className="form"
                                style={{ marginLeft: 10, marginTop: 10 }}
                            >
                                {Object.entries(errors).map((err, index) => (
                                    <div key={index.toString()}>
                                        <div className="form-error">
                                            {removeSnake(err[0])?.toUpperCase()}
                                            :
                                        </div>
                                        <FormError errors={err[1]} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )}
            </Content>
            <Footer>
                <FooterButtons
                    disabled={
                        !checkedMember?.id ||
                        !checkedCurrency?.id ||
                        !amount ||
                        !date
                    }
                    invitationDisabled={
                        (organizations?.length === 1 &&
                            !!invitationFields?.email &&
                            !!isEmail(invitationFields?.email)) ||
                        !invitationFields?.email ||
                        (!!invitationFields?.email &&
                            !isEmail(invitationFields?.email)) ||
                        !!Object.keys(invitationErrors).length
                    }
                    inviteForm={isInvitationFormVisible}
                    onCancel={
                        isInvitationFormVisible
                            ? closeInvitationForm
                            : onRequestClose
                    }
                    onSubmit={isInvitationFormVisible ? invite : onSubmit}
                />
            </Footer>
            {!!isLoading && <Spinner />}
        </Modal>
    );
};
