import { useCallback, useState } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MerchantStatus } from './style';
import { amountOptions, getInitials } from '../../services/helpers';
import { signOut } from '../../services/SignOutService';
import { changeOrganization } from '../../services/UsersService';
import { chainsSet } from '../../state/chains';
import { circlesSet } from '../../state/circles';
import { countsReset } from '../../state/collectionsCount';
import { connectedFactoringOrganizationsSet } from '../../state/connectedFactoringOrganizations';
import { externalConnectionsSet } from '../../state/externalConnections';
import { groupsSet } from '../../state/groups';
import { membersSet } from '../../state/members';
import {
    getAbilityToManageTransactions,
    getMyCurrentOrganizationName,
    getMyOrganizations,
} from '../../state/myOrganizations';
import { notificationsSet } from '../../state/notifications';
import { organizationsSet } from '../../state/organizations';
import { factoringOrganizationsSet } from '../../state/organizationsFactoring';
import { factoringClientsSet } from '../../state/organizationsFactoringClients';
import { receiptsSet } from '../../state/receipts';
import { reportsSet } from '../../state/reports';
import {
    getMyCurrentOrganizationId,
    getSumNotifications,
    getTotalNetted,
} from '../../state/statistics';
import { transactionsSet } from '../../state/transactions';
import { getMyUser, selectIsIndividual } from '../../state/user';

const customStyles = {
    overlay: {
        backgroundColor: 'transparent',
        zIndex: 9999999,
    },
    content: {
        top: '50px',
        left: '210px',
        right: 'auto',
        bottom: 'auto',
        padding: 0,
        overflow: 'none',
        border: 0,
        boxShadow: '1px 2px 25px 2px rgba(0,0,0,.15)',
        borderRadius: 10,
        color: '#fff',
        font: '700 18px/25px "Azo Sans", "Roboto", arial, sans-serif',
    },
};

export const MenuUser = () => {
    const user = useSelector(getMyUser);
    const myCurrentOrganizationName = useSelector(getMyCurrentOrganizationName);
    const myCurrentOrganizationId = useSelector(getMyCurrentOrganizationId);
    const myOrganizations = useSelector(getMyOrganizations);
    const totalNetted = useSelector(getTotalNetted);
    const sumNotifications = useSelector(getSumNotifications);

    const [dropdown, setDropdown] = useState(false);

    // temporary
    const toggleDropdown = useCallback(
        e => {
            e.preventDefault();
            setDropdown(!dropdown);
        },
        [dropdown],
    );

    const history = useHistory();

    const createOrganization = useCallback(() => {
        setDropdown(false);
        history.push(`/new-organization`);
    }, [history]);

    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const switchOrganization = useCallback(
        async (id: number) => {
            setDropdown(false);
            if (!!id && id !== myCurrentOrganizationId) {
                setLoading(true);
                dispatch(countsReset());
                dispatch(transactionsSet());
                dispatch(receiptsSet());
                dispatch(circlesSet());
                dispatch(chainsSet());
                dispatch(reportsSet());
                dispatch(externalConnectionsSet());
                dispatch(notificationsSet());
                dispatch(organizationsSet());
                dispatch(factoringOrganizationsSet());
                dispatch(factoringClientsSet());
                dispatch(connectedFactoringOrganizationsSet([]));
                dispatch(membersSet());
                dispatch(groupsSet());

                await changeOrganization(Number(id));
                setLoading(false);
            }
        },
        [dispatch, myCurrentOrganizationId],
    );

    const editProfile = useCallback(() => {
        setDropdown(false);
        history.push(`/profile`);
    }, [history]);

    const abilityToManageTransactions = useSelector(
        getAbilityToManageTransactions,
    );

    const isIndividual = useSelector(selectIsIndividual);

    return (
        <div className="menu-user">
            <div className="menu-user-name">
                <div className="menu-user-name-text">
                    {myCurrentOrganizationName || 'New organization'}
                </div>
                <button
                    type="button"
                    className={`menu-user-switch ${dropdown ? 'active' : ''}`}
                    onClick={toggleDropdown}
                >
                    <em className="mdi mdi-menu-down" />
                </button>
                <Loader
                    type="BallTriangle"
                    color="white"
                    visible={isLoading}
                    height={15}
                    width={55}
                />
                {dropdown && (
                    <Modal
                        isOpen={dropdown}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={() => setDropdown(false)}
                        style={customStyles}
                        contentLabel="User menu"
                    >
                        {/* <div className="menu-user-dropdown"> */}
                        <div className="dropdown dropdown-user_selector">
                            <div className="user_selector-client">
                                <div className="user_selector-media">
                                    {/* when image is needed */}
                                    {user?.avatar?.sizes?.thumbnail ? (
                                        <div className="user_selector-media-image">
                                            <img
                                                src={
                                                    user?.avatar?.sizes
                                                        ?.thumbnail
                                                }
                                                alt=""
                                            />
                                        </div>
                                    ) : (
                                        !!user?.first_name &&
                                        !!user?.last_name && (
                                            <div className="user_selector-media-abbr">
                                                {getInitials(
                                                    `${user?.first_name} ${user?.last_name}`,
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="user_selector-details">
                                    <div className="user_selector-client-name">
                                        {`${
                                            !!user?.first_name &&
                                            user?.first_name
                                        } ${
                                            !!user?.last_name && user?.last_name
                                        }`}
                                    </div>
                                    <div className="user_selector-client-email">
                                        {user?.email}
                                    </div>
                                </div>
                            </div>
                            <div className="user_selector-organizations">
                                {Object.values(myOrganizations)?.map(
                                    (item, index) => (
                                        <div
                                            role="button"
                                            tabIndex={0}
                                            key={index.toString()}
                                            className="user_selector-organization"
                                            onClick={() =>
                                                switchOrganization(item?.id)
                                            }
                                            onKeyDown={() =>
                                                switchOrganization(item?.id)
                                            }
                                        >
                                            <div
                                                className="user_selector-media"
                                                style={{ position: 'relative' }}
                                            >
                                                {item?.logo?.sizes
                                                    ?.thumbnail ? (
                                                    <div className="user_selector-media-image">
                                                        <img
                                                            src={
                                                                item?.logo
                                                                    ?.sizes
                                                                    ?.thumbnail
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="user_selector-media-abbr"
                                                        style={{
                                                            backgroundColor:
                                                                '#fda942',
                                                        }}
                                                    >
                                                        {getInitials(
                                                            item?.company_name,
                                                        )}
                                                    </div>
                                                )}
                                                {!!item?.merchant?.status && (
                                                    <MerchantStatus
                                                        status={
                                                            item?.merchant
                                                                ?.status
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className="user_selector-organization-name"
                                                style={{
                                                    maxWidth:
                                                        myCurrentOrganizationId ===
                                                            item?.id ||
                                                        !!sumNotifications
                                                            ? 150
                                                            : undefined,
                                                }}
                                            >
                                                {item?.company_name}
                                            </div>
                                            {myCurrentOrganizationId ===
                                            item?.id ? (
                                                <div className="user_selector-organization-checkmark">
                                                    <div className="mdi mdi-check" />
                                                </div>
                                            ) : (
                                                !!item?.statistics
                                                    ?.sum_notifications && (
                                                    <div className="user_selector-organization-menu-item-notes">
                                                        {
                                                            item?.statistics
                                                                ?.sum_notifications
                                                        }
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ),
                                )}
                                {!isIndividual && (
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        className="user_selector-organization"
                                        onClick={createOrganization}
                                        onKeyDown={createOrganization}
                                    >
                                        <div className="user_selector-media">
                                            <div className="user_selector-media-abbr">
                                                <div className="mdi mdi-plus" />
                                            </div>
                                        </div>
                                        <div className="user_selector-organization-name">
                                            Create new organization
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="user_selector-actions">
                                <div
                                    className="user_selector-action"
                                    role="button"
                                    tabIndex={-1}
                                    onClick={editProfile}
                                    onKeyDown={editProfile}
                                >
                                    <div className="user_selector-action-icon">
                                        <div className="mdi mdi-account-outline" />
                                    </div>
                                    <div className="user_selector-action-name">
                                        Account settings
                                    </div>
                                </div>
                                <div
                                    className="user_selector-action"
                                    role="button"
                                    tabIndex={-2}
                                    onClick={signOut}
                                    onKeyDown={signOut}
                                >
                                    <div className="user_selector-action-icon">
                                        <div className="mdi mdi-logout" />
                                    </div>
                                    <div className="user_selector-action-name">
                                        Log out
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </Modal>
                )}
            </div>
            <div className="menu-user-balance">{`${user?.first_name} ${user?.last_name}`}</div>
            {!!totalNetted &&
                !!user?.currency?.symbol &&
                abilityToManageTransactions && (
                    <div className="menu-user-balance">{`Netted ${
                        user?.currency?.symbol
                    } ${totalNetted?.toLocaleString(
                        'en',
                        amountOptions,
                    )}`}</div>
                )}
            <div className="menu-user-uid">{`ID #${myCurrentOrganizationId}`}</div>
            <div className="menu-user-email">{user?.email}</div>
        </div>
    );
};
