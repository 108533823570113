import { useSelector } from 'react-redux';
import { TdAmount } from './TdAmount';

import { TdDate } from './TdDate';
import { TdRecipient } from './TdRecipient';

import { TdStatus } from './TdStatus';
import { TBalancePaymentHistory } from '../../types/types';
import { selectUserCurrency } from '../../state/user';

type Props = {
    transaction?: TBalancePaymentHistory;
};

export const Tr = ({ transaction }: Props) => {
    const currency = useSelector(selectUserCurrency);
    return (
        <tr className="tr-compact">
            <TdStatus id={transaction?.id} status={transaction?.state} />

            <TdDate id={transaction?.id} date={transaction?.created_at} />
            <TdDate id={transaction?.id} date={transaction?.updated_at} />
            <TdRecipient
                id={transaction?.id}
                recipient={`#${transaction?.user_id}`}
            />

            <TdAmount
                id={transaction?.id}
                icon={currency?.icon?.svg}
                amount={transaction?.amount}
            />
        </tr>
    );
};
