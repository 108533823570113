import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import lbbwIcon from './lbbw.svg';
import { Iban, IbanPrefix, IbanWrapper } from './style';
import briefcaseIcon from '../../assets/img/icons/icon-briefcase.svg';
import chainIcon from '../../assets/img/icons/icon-chain.svg';
import circleIcon from '../../assets/img/icons/icon-circle.svg';
import handshakeIcon from '../../assets/img/icons/icon-handshake.svg';
import { amountOptions } from '../../services/helpers';
import { getStatistics } from '../../state/statistics';
import { getMyUser, selectIsIndividual } from '../../state/user';
import { BalanceModal } from '../../components';

export const TotalAmounts = () => {
    const user = useSelector(getMyUser);
    const statistics = useSelector(getStatistics);
    const isIndividual = useSelector(selectIsIndividual);
    const balance = user?.balance ? Number(user?.balance) || 0 : 0;
    const onBallancePress = useCallback(() => setBalanceModalVisible(true), []);
    const [isBalanceModalVisible, setBalanceModalVisible] = useState(false);
    console.log('isIndividual', isIndividual);
    console.log('user?.currency?.icon?.svg', user?.currency?.icon?.svg);
    return (
        <div className="block block-overview_cards">
            {isIndividual && !!user?.currency?.icon?.svg && (
                <div
                    className="overview_card"
                    style={{
                        cursor: 'pointer',
                    }}
                    role="button"
                    tabIndex={0}
                    onClick={onBallancePress}
                    onKeyDown={onBallancePress}
                >
                    <div
                        className="overview_card-title"
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="overview_card-title-label">
                            LBBW Account Balance
                        </div>
                        <div
                            className="overview_card-title-icon"
                            style={{ alignSelf: 'flex-end' }}
                        >
                            <img src={lbbwIcon} alt="handshake icon" />
                        </div>
                    </div>
                    <div className="overview_card-value">
                        <div className="overview_card-value-currency">
                            <img
                                src={user?.currency?.icon?.svg}
                                alt="currency icon"
                            />
                        </div>
                        <div className="overview_card-value-amount">
                            {balance?.toLocaleString('en', amountOptions)}
                        </div>
                    </div>
                    <IbanWrapper>
                        <IbanPrefix>IBAN:</IbanPrefix>
                        <Iban>**** 2769</Iban>
                    </IbanWrapper>
                </div>
            )}
            {(!!statistics?.total_payables ||
                statistics?.total_payables === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={handshakeIcon} alt="handshake icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Total payables
                            </div>
                        </div>
                        <div className="overview_card-value overview_card-value-danger">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.total_payables > 0 ? '-' : ''}
                                {statistics?.total_payables?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}

            {(!!statistics?.total_receivables ||
                statistics?.total_receivables === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={briefcaseIcon} alt="briefcase icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Total receivables
                            </div>
                        </div>
                        <div className="overview_card-value">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.total_receivables?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}
            {(!!statistics?.circular_netted ||
                statistics?.circular_netted === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={circleIcon} alt="circle-icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Circular netted
                            </div>
                        </div>
                        <div className="overview_card-value overview_card-value-success">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.circular_netted?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}
            {(!!statistics?.chain_netted || statistics?.chain_netted === 0) &&
                !!user?.currency?.icon?.svg && (
                    <div className="overview_card">
                        <div className="overview_card-title">
                            <div className="overview_card-title-icon">
                                <img src={chainIcon} alt="chain icon" />
                            </div>
                            <div className="overview_card-title-label">
                                Chain netted
                            </div>
                        </div>
                        <div className="overview_card-value overview_card-value-success">
                            <div className="overview_card-value-currency">
                                <img
                                    src={user?.currency?.icon?.svg}
                                    alt="currency icon"
                                />
                            </div>
                            <div className="overview_card-value-amount">
                                {statistics?.chain_netted?.toLocaleString(
                                    'en',
                                    amountOptions,
                                )}
                            </div>
                        </div>
                    </div>
                )}
            {!!isBalanceModalVisible && (
                <BalanceModal
                    isModalOpen={isBalanceModalVisible}
                    onRequestClose={() => setBalanceModalVisible(false)}
                />
            )}
        </div>
    );
};
