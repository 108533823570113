import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Messaging, onMessage } from 'firebase/messaging';
import { FiltersHeader } from './FiltersHeader';
import { Tr } from './Tr';
import {
    AppHeader,
    BalanceModal,
    CurrenciesFooter,
    PaginationButtons,
    Th,
} from '../../components';
import { fetchCurrentOrganizationStatistics } from '../../services/OrganizationsService';
import { fetchPaymentsHistory, fetchUser } from '../../services/UsersService';
import { getHeaderHelper2 } from '../../services/helpers';
import { getMyCurrentOrganizationId } from '../../state/statistics';
import { TBalancePaymentHistory } from '../../types/types';
import { TotalAmounts } from '../Home/TotalAmounts';
import { messagingPromise } from '../../services/firebase';
import { store } from '../../state/store';

type SortField = 'state' | 'created_at';
// | 'to_organization_id'
// | 'from_organization_id'
// | 'payment_status'
// | 'amount'
// | 'amount_due'
// | 'request_status';

export const PaymentsHistory = () => {
    const [sortField, setSortField] = useState<SortField>('created_at');
    const [sortOrder, setSortOrder] = useState<{
        [key: string]: 'ASC' | 'DESC';
    }>({
        status: 'DESC',
        created_at: 'DESC',
        // to_organization_id: 'DESC',
        // payment_status: 'DESC',
        // amount: 'DESC',
        // amount_due: 'DESC',
    });

    const setOrderMethod = useCallback(
        (value: SortField) => {
            setSortField(prevState => {
                if (prevState === value) {
                    if (sortOrder[value] === 'ASC') {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'DESC',
                        }));
                    } else {
                        setSortOrder(prev => ({
                            ...prev,
                            [value]: 'ASC',
                        }));
                    }
                }
                return value;
            });
        },
        [sortOrder],
    );

    const [transactions, setTransactions] = useState<TBalancePaymentHistory[]>(
        [],
    );

    const [isModalOpen, setModalOpen] = useState(false);

    const showModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    // const [query, setQuery] = useState<string>('');

    // const [delayQuery, setDelayQuery] = useState<string>();

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //         setQuery(delayQuery?.toUpperCase() || '');
    //     }, 350);

    //     return () => clearTimeout(delayDebounceFn);
    // }, [delayQuery]);

    const location = useLocation();

    const [meta, setMeta] = useState({
        current_page: 0,
        last_page: 1,
        total: 0,
    });

    const [isLoading, setLoading] = useState(false);
    const [status, setStatus] = useState<TBalancePaymentHistory['state'][]>([]);

    const fetchTransactionsMethod = useCallback(
        async (page = 1, update = false) => {
            setLoading(true);
            const response = await fetchPaymentsHistory({
                page,
                state: status?.length ? status?.toString() : '',
                // q: query,
                sortField,
                sortOrder: sortOrder[sortField],
            });
            setLoading(false);
            if (response?.status === 200) {
                setTransactions(prevState =>
                    update
                        ? [...prevState, ...(response?.data?.data || [])]
                        : response?.data?.data || [],
                );
                if (response?.data?.meta) {
                    setMeta(response?.data?.meta);
                }
            }
        },
        [sortField, sortOrder, status],
    );

    const currentOrganizationId = useSelector(getMyCurrentOrganizationId);

    useEffect(() => {
        (async () => {
            if (currentOrganizationId) {
                await fetchTransactionsMethod();
                await fetchUser();
            }
            await fetchCurrentOrganizationStatistics();
        })();
    }, [currentOrganizationId, fetchTransactionsMethod]);

    const onPressNext = useCallback(async () => {
        await fetchTransactionsMethod(meta.current_page + 1);
    }, [fetchTransactionsMethod, meta.current_page]);

    const onPressPrev = useCallback(async () => {
        await fetchTransactionsMethod(meta.current_page - 1);
    }, [fetchTransactionsMethod, meta.current_page]);

    const headerTitle = useMemo(() => {
        return getHeaderHelper2(location?.pathname);
    }, [location?.pathname]);

    const onPressMore = useCallback(async () => {
        await fetchTransactionsMethod(meta.current_page + 1, true);
    }, [fetchTransactionsMethod, meta.current_page]);

    const onPressFirst = useCallback(async () => {
        await fetchTransactionsMethod(1);
    }, [fetchTransactionsMethod]);

    const onPressLast = useCallback(async () => {
        await fetchTransactionsMethod(meta?.last_page);
    }, [fetchTransactionsMethod, meta?.last_page]);

    const onPressPage = useCallback(
        async (page: number) => {
            await fetchTransactionsMethod(page);
        },
        [fetchTransactionsMethod],
    );

    const [messaging, setMessaging] = useState<Messaging | undefined>();

    useEffect(() => {
        (async () => {
            const firebaseMessaging = await messagingPromise();
            setMessaging(firebaseMessaging);
        })();
    }, []);

    useEffect(() => {
        let unsubscribe;

        if (messaging) {
            unsubscribe = onMessage(messaging, async remoteMessage => {
                const type = remoteMessage?.data?.notificatable_type;
                const organizationId = remoteMessage?.data?.organization_id;
                const currentOrgId = getMyCurrentOrganizationId(
                    store.getState(),
                );
                if (type && currentOrgId === organizationId) {
                    switch (type) {
                        case 'balance':
                            await fetchTransactionsMethod();
                            break;
                        default:
                            break;
                    }
                }
            });
        }

        return unsubscribe;
    }, [fetchTransactionsMethod, messaging]);

    return (
        <div className="app-container">
            <AppHeader
                title={headerTitle}
                // transactionButton={canCreateTransactions}
                singleButtonText="Top Up Balance"
                singleButtonMdi="plus"
                onSingleButtonPress={showModal}
                isButtonDisabled={isLoading}
            />
            <div className="app-content">
                <div className="app-body">
                    <TotalAmounts />
                    <FiltersHeader
                        isLoading={isLoading && !!meta?.total}
                        // query={delayQuery}
                        status={status}
                        // onQueryChange={setDelayQuery}
                        onStatusChange={setStatus}
                    />
                    {!!meta?.total && (
                        <div className="table" style={{ position: 'relative' }}>
                            <table>
                                <tbody>
                                    <tr className="tr-overview">
                                        <Th
                                            title="Payment state"
                                            field="state"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.status}
                                            onPress={() =>
                                                setOrderMethod('state')
                                            }
                                        />

                                        <Th
                                            title="Created Date"
                                            field="created_at"
                                            sortField={sortField}
                                            sortOrder={sortOrder?.created_at}
                                            onPress={() =>
                                                setOrderMethod('created_at')
                                            }
                                        />

                                        <Th
                                            title="Updated Date"
                                            field="updated_at"
                                            sortField={sortField}
                                            // sortOrder={
                                            //     sortOrder?.to_organization_id
                                            // }
                                            // onPress={() =>
                                            //     setOrderMethod(
                                            //         'to_organization_id',
                                            //     )
                                            // }
                                        />

                                        <Th
                                            title="User ID"
                                            field="user_id"
                                            sortField={sortField}
                                            // sortOrder={
                                            //     sortOrder?.from_organization_id
                                            // }
                                        />

                                        <Th
                                            title="Amount"
                                            field="amount"
                                            sortField={sortField}
                                            sortOrder={
                                                sortOrder?.payment_status
                                            }
                                            // onPress={() =>
                                            //     setOrderMethod('payment_status')
                                            // }
                                        />
                                    </tr>

                                    {transactions?.length
                                        ? transactions.map(transaction => (
                                              <Tr
                                                  key={transaction?.id.toString()}
                                                  transaction={transaction}
                                              />
                                          ))
                                        : isLoading
                                        ? Array.from(
                                              Array(
                                                  Math.min(meta?.total, 10),
                                              ).keys(),
                                          )?.map(item => (
                                              <Tr key={item?.toString()} />
                                          ))
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <PaginationButtons
                        isLoading={isLoading}
                        prevDisabled={meta?.current_page === 1 || isLoading}
                        nextDisabled={
                            meta?.current_page === meta?.last_page || isLoading
                        }
                        currentPage={meta?.current_page}
                        lastPage={meta?.last_page}
                        pagesDisabled={isLoading}
                        total={meta?.total}
                        onPressPrev={onPressPrev}
                        onPressNext={onPressNext}
                        onPressMore={onPressMore}
                        onPressFirst={onPressFirst}
                        onPressLast={onPressLast}
                        onPressPage={onPressPage}
                    />
                </div>
            </div>
            <CurrenciesFooter />

            {isModalOpen && (
                <BalanceModal
                    isModalOpen={isModalOpen}
                    onRequestClose={closeModal}
                />
            )}
        </div>
    );
};
