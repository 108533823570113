/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import {
    Center,
    CloseButton,
    CloseButtonText,
    Content,
    CurrencyIcon,
    Footer,
    Header,
    HeaderSubtitle,
    HeaderTitle,
} from './styles';
import { FooterButtons, FormError, Row, Spinner } from '..';
import { selectMyBalance, selectUserCurrency } from '../../state/user';
import { amountOptions, number } from '../../services/helpers';
import { topUpMyBallance } from '../../services/UsersService';

const customStyles = {
    overlay: {
        backgroundColor: '#00000066',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        padding: 0,
        overflow: 'none',
    },
};

Modal.setAppElement('body');

type Props = {
    isModalOpen: boolean;
    onRequestClose: () => void;
};

const allFields = ['amount'];
const requiredFields = allFields;

export const BalanceModal = ({ isModalOpen, onRequestClose }: Props) => {
    const [errors, setErrors] = useState<{ [key: string]: any }>({});
    const [otherErrors, setOtherErrors] = useState<{ [key: string]: any }>({});
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isFooterButtonsVisible, setFooterButtonsVisible] = useState(false);
    const [amount, setAmount] = useState<string | ''>();
    const amountChanged = useCallback(
        (value: string) => {
            setOtherErrors({});
            setFooterButtonsVisible(true);
            setAmount(value ? number(value) : '');
            if (!value) {
                const errorMessage = `The amount should be greater than zero`;

                if (!errors.hasOwnProperty('amount'))
                    setErrors(prevState => {
                        return { ...prevState, amount: [errorMessage] };
                    });
            } else {
                setErrors(prevState => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { amount: _, ...res } = prevState;
                    return res;
                });
            }
        },
        [errors],
    );
    const checkEmptyValues = useCallback(() => {
        let allFieldsFilled = true;
        requiredFields.forEach(item => {
            if (!errors.hasOwnProperty(item) && !amount) {
                allFieldsFilled = false;
                const errorMessage = `The amount should be greater than zero`;
                setErrors(prevState => {
                    return {
                        [item]: [errorMessage],
                        ...prevState,
                    };
                });
            }
        });
        return allFieldsFilled;
    }, [amount, errors]);

    const onSubmit = useCallback(async () => {
        if (!Object.keys(errors).length) {
            const allFieldsFilled = checkEmptyValues();
            if (allFieldsFilled) {
                setButtonDisabled(true);
                const response = await topUpMyBallance(amount);
                setButtonDisabled(false);

                if (response?.status === 200 || response?.status === 201) {
                    onRequestClose();
                }
                if (
                    response?.response?.status === 422 &&
                    response?.response?.data?.errors
                ) {
                    const allErrors = response?.response?.data?.errors;
                    const filteredErrors = Object.keys(allErrors)
                        .filter(key => allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});

                    const otherFilteredErrors = Object.keys(allErrors)
                        .filter(key => !allFields.includes(key))
                        .reduce((obj, key) => {
                            return {
                                ...obj,
                                [key]: allErrors[key],
                            };
                        }, {});
                    setErrors(filteredErrors);
                    setOtherErrors(otherFilteredErrors);
                }
            }
        }
    }, [amount, checkEmptyValues, errors, onRequestClose]);

    const balance = useSelector(selectMyBalance);
    const currency = useSelector(selectUserCurrency);

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            contentLabel="My balance"
        >
            <Header>
                <Row>
                    <div
                        style={{
                            boxShadow: 'inset -1px 0px 0px #EAECF0',
                            paddingRight: 15,
                        }}
                    >
                        <HeaderSubtitle>Balance</HeaderSubtitle>
                        <Row marginTop={6} marginBottom={12}>
                            <HeaderTitle>
                                {balance?.toLocaleString('en', amountOptions)}
                            </HeaderTitle>
                            {!!currency?.icon?.svg && (
                                <CurrencyIcon
                                    src={currency?.icon?.svg}
                                    alt=""
                                />
                            )}
                        </Row>
                    </div>
                    <div style={{ paddingLeft: 15 }}>
                        <HeaderTitle>Top Up Balance</HeaderTitle>
                        <HeaderSubtitle>Enter amount</HeaderSubtitle>
                    </div>
                </Row>
                <CloseButton onClick={onRequestClose}>
                    <CloseButtonText className="mdi mdi-close start" />
                </CloseButton>
            </Header>

            <Content>
                <div className="wrapper wrapper-sm form" style={{ margin: 0 }}>
                    <div className="block block-pay-modal">
                        <Center>
                            <div className="wrapper wrapper-sm form">
                                <div className="form-group col-lg-12 col-xs-12">
                                    <div className="form-control-wrapper">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Amount"
                                            value={amount}
                                            onChange={e =>
                                                amountChanged(e.target.value)
                                            }
                                        />
                                        <div className="form-control-info">
                                            <em className="mdi mdi-information form-control-info-icon" />
                                            <div className="form-control-info-container form-control-info-container-dim">
                                                Amount to top up your balance
                                            </div>
                                        </div>
                                    </div>
                                    <FormError errors={errors?.amount} />
                                </div>
                            </div>
                        </Center>
                    </div>
                    {!!Object.keys(otherErrors)?.length && (
                        <>
                            <div className="form-title">
                                {`Other error${
                                    Object.keys(otherErrors)?.length > 1
                                        ? 's:'
                                        : ':'
                                }`}
                            </div>
                            {Object.keys(otherErrors)?.map(otherErrorKey => (
                                <FormError
                                    key={otherErrorKey}
                                    errors={otherErrors[otherErrorKey]}
                                />
                            ))}
                        </>
                    )}
                </div>
            </Content>

            <Footer>
                <FooterButtons
                    disabled={isButtonDisabled || !isFooterButtonsVisible}
                    chainPaymentForm
                    onCancel={onRequestClose}
                    onSubmit={onSubmit}
                />
            </Footer>

            {!!isButtonDisabled && <Spinner />}
        </Modal>
    );
};
